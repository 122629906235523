<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top">
                <h2><span>■</span> 상담사승인</h2>
            </div>
            <div class="con">
                <div class="btns mb-30">
                        <a class="btn_new2 pointer" @click="register()">상담사등록</a>
                </div>
                <div class="con_table">
                        <select v-model="permission" @change="getUserList()" class="float-left font-16 w-130px h-40px mb-20">
                            <option value="">진행현황선택</option>
                            <option value="Y">승인완료</option>
                            <option value="N">승인신청</option>
                        </select>
                        <input type="text" v-model="name" @keyup.enter="getUserList()" class="float-left ml-20 w-200px h-40px pl-20" placeholder="상담사명">
                         <a class="float-left btn_search ml-10 pointer mt-10" @click="getUserList()">Search<span></span></a>
                         
                    <div class="search2">
                        <input type="hidden" name="_token" id="_token" value="EqLJL0tVa23F5ikcDmzYVhVNw50lB9k5eDl840Bi">
                        <input type="hidden" name="this_month" id="this_month" value="2020-10">
                        <div>기간 검색 :
                            <crm-datepicker v-model="startDate" :parentDate="startDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker> ~
                            <crm-datepicker v-model="endDate" :parentDate="endDate" class="mr-10" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                            <a @click="getUserList()" class="btn_search pointer">Search<span></span></a>
                        </div>
                    </div>
                    <table class="default_table">
                        <tbody v-if="loading">
                            <tr>
                                <th>No</th>
                                <th>날짜</th>
                                <th>센터</th>
                                <th>상담사</th>
                                <th>전화번호</th>
                                <th>진행현황</th>
                                <th>관리</th>
                            </tr>
                            <tr>
                                <td colspan="6">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th>No</th>
                                <th>날짜</th>
                                <th>센터</th>
                                <th>상담사</th>
                                <th>전화번호</th>
                                <th>진행현황</th>
                                <th>관리</th>
                            </tr>
                            <tr v-for="(item, index) of userList" :key="index">
                                <td>{{item.rownum}}</td>
                                <td>{{item.createDate.slice(0,10)}}</td>
                                <td>{{item.centerName}}</td>
                                <td class="underline pointer" @click="detailCard(item.idx)">{{item.name}}</td>
                                <td>{{item.phone}}</td>
                                <td>{{formatYN(item.permission)}}</td>
                                <td>
                                    <span v-if="item.permission !== 'Y'" class="underline pointer" @click="putPermission(item.idx)">승인</span>
                                </td>
                            </tr>
                            <tr v-if="userList.length === 0">
                                <td colspan="6">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!-- <detail v-if="onModal" @close="offModal()"></detail> -->
    <detail v-on:permission="putPermission" :onModal.sync="onModal" :user.sync="user" :idx.sync="idx"></detail>
</div>
</template>

<script>
import Detail from '@/components/admin/confirmCounselor/detail.vue'
export default {
    components: {
        Detail
    },
    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜
        onModal: false, // 미리보기 모달 on/off
        idx: -1,
        type: 'STAFF',

        permission: '',
        name: '',
        user: {},

        userList: [],

        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        loading:true,
    }),

    mounted() {
        this.startDate = this.formatDate();
        this.endDate = this.formatDate();
        this.getUserList();
    },

    methods: {

        // 상담사 카드 페이지로 이동
        detailCard(idx) {
            this.$router.push(`/convention/counselor_manage_card?idx=${idx}`)
        },
        // 오늘 날짜 YYYY-MM-DD로 지정하는 함수
        formatDate() {
            var d = new Date(),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [year, month, day].join('-');
        },

        // 상담일지 미리보기 (모달 on)
        detail(userList, idx) {
            this.onModal = !this.onModal
            this.user = userList
            this.idx = idx
        },

        // 상담일지 닫기 (모달 off)
        offModal() {
            this.onModal = false
        },

        formatYN(flag) {
            if (flag === 'Y') {
                return '승인완료'
            } else {
                return '승인신청'
            }
        },

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getUserList()
            }
        },
        paging(index) {
            this.pageNum = index
            this.getUserList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getUserList()
            }
        },

        // 검색 api
        getUserList() {
            var params = {
                startDate: this.startDate,
                endDate: this.endDate,
                name: this.name,
                permission: this.permission,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            };

            this.loading = true;

            this.axios.get('/api/v2/user/list', {
                    params: params
                })
                .then(res => {
                    this.userList = res.data.userList
                    if (res.data.userList) {
                        this.listLength = res.data.userListCount
                    }

                    this.idxCrmCenter = res.data.idxCrmCenter
                    this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                    this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                    this.pagePart = Math.ceil(this.pageNum / 10)
                })
                .catch(err => {
                    console.log(err)
                }).finally(()=>{
                    this.loading = false;
                });
        },

        // 승인
        putPermission(idx) {

            this.idx = idx

            var params = new URLSearchParams();
            params.append('type', this.type)
            params.append('idx', this.idx)

            this.axios.put('/api/v1/admin/permission', params)
                .then(res => {
                    alert('승인 되었습니다.')
                    this.$router.go(this.$router.currentRoute)
                })
                .catch(err => {
                    alert(err)
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })

        },

        // 센터등록 페이지로 이동
        register() {
            //this.$router.push('/convention/counselor_manage_register')
            this.$router.push(`/convention/counselor_manage_register?idxCrmCenter=${this.idxCrmCenter}`)
        },

    }
}
</script>
